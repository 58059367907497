import { createContext, useState, useContext, useEffect } from "react";
import Cookies from "universal-cookie/es6";
import { UserModel } from "../model/user";
import auth from "../services/auth";

// O que o contexto irá passar para o outros componentes
interface AuthContextData {
  signed: boolean;
  user: UserModel | null;
  loading: boolean;
  checkEmail(email: string): Promise<void>;
  signOut(redirect: boolean): void;
  getUserCookie(): UserModel | null;
  getUserAzure(): Promise<UserModel | null>;
  getInfoByEmail(email: string): Promise<UserModel | null>;
  setUserCookie(user: any): void;
  clearUserState(): void;
}

// Como o objeto de contexto vai iniciar
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

type Props = {
  children?: JSX.Element;
};

// Componente de contexto que irá por volta de todos os outros componentes
// eslint-disable-next-line
export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUserState] = useState<UserModel | null>(null);
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();

  useEffect(() => {
    (async () => {
      const userCookie = getUserCookie();
      if (userCookie) {
        setUserState(userCookie);
      }
      const userAzure = await getUserAzure();
      if (userAzure) {
        const token = await auth.token(userAzure.email);
        // debugger
        if (token) {
          if (window.location.hostname === "localhost") {
            // debugger
            cookies.set("access_token_cookie1", token, {
              sameSite: "none",
              secure: true,
              domain: "localhost",
            });
          } else {
            cookies.set("access_token_cookie", token, {
              sameSite: "strict",
            });
          }
        }
        const userInfo = await auth.getInfoByEmail(userAzure.email);
        setUserCookie(userInfo);
      } else {
        clearUserState();
        if (userCookie) signOut(false);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);

  async function getUserAzure(): Promise<UserModel | null> {
    if (process.env.REACT_APP_BASE_BACK) {
      console.log(getUserCookie());
      return new Promise((resolve) => resolve(getUserCookie()));
    } else {
      return auth.current();
    }
  }

  async function getInfoByEmail(email: string) {
    const user = await auth.getInfoByEmail(email);
    if (user && user.email == cookies.get("@RNauth:email")) {
      cookies.remove("@RNauth:email");
      setUserCookie(user);
      return user;
    } else {
      return null;
    }
  }

  function getUserCookie() {
    const storagedUser = cookies.get("@RNauth:user");
    return storagedUser ? storagedUser : null;
  }

  function setUserCookie(response: UserModel) {
    if (response) {
      const date = new Date();
      date.setFullYear(date.getUTCFullYear() + 1);
      cookies.set("@RNauth:user", response, {
        path: "/",
        httpOnly: false,
      });
      setUserState(response);
    }
  }

  async function checkEmail(email: string) {
    // Requisição a api que vai fazer com que o usuário logue na aplicação
    const response: any = await auth.checkEmail(email);
    if (response) {
      cookies.set("@RNauth:email", email, {
        path: "/",
        httpOnly: false,
      });
    }
    // setUser(response);
    return response;
  }

  function clearUserState() {
    setUserState(null);
  }

  async function signOut(redirect = true) {
    // Limpa a informação no AsyncStorage
    cookies.remove("@RNauth:user");
    cookies.remove("StaticWebAppsAuthCookie");
    cookies.remove("@RNauth:layout");
    cookies.remove("access_token");
    // cookies.remove("remember_token");
    // cookies.remove("session");
    document.cookie = "";
    // setUserState(null);
    if (redirect) {
      if (window.location.hostname === "localhost") {
        // debugger;
        window.location.href = `${window.location.origin}/login`;
      } else {
        window.location.href = `/.auth/logout?post_logout_redirect_uri=${window.location.origin}/login`;
      }
    }
  }

  return (
    // O estado se o usuário está logado ou não depende se algo dentro do estado "user"
    // Ou seja se existir algo dentro do user ele irá retornar true
    // eslint-disable-next-line
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        checkEmail,
        signOut,
        getUserCookie,
        getUserAzure,
        clearUserState,
        getInfoByEmail,
        setUserCookie,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
