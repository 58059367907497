// @flow
import * as React from "react";
import { Row, Col, Button, Collapse, Card } from "react-bootstrap";
import { BsFillEraserFill } from "react-icons/bs";
import { FaFilter, FaUserPlus } from "react-icons/fa";
import { AiOutlineReload } from "react-icons/ai";
import "./index.scss";

interface Props {
  title: JSX.Element | string;
  clearFilter: () => void;
  reload: () => void;
  add?: () => void;
  children?: JSX.Element

}

export const FilterHeader: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);


  return (
    <Card className="text-center margin-top-10 margin-bottom-10   ">
      <div className="h-100 align-vertical  click  height-50 " >

        <h5 className="text-tertiary font-size-18 mb-0 w-100 line-height-50" onClick={() => setOpen(!open)}>
          <b> {props.title} </b>
        </h5>
        <div className="position-absolute right-12">
          <Button onClick={props.reload} size="sm"  variant="outline-primary">
            <AiOutlineReload />
          </Button>


          <Button onClick={() => setOpen(!open)} size="sm"  variant="outline-primary">
            <FaFilter />
          </Button>

          <Button size="sm"  variant="outline-primary"
            onClick={() => {
              props.clearFilter();
            }}
          >
            <BsFillEraserFill />
          </Button>

            {props.add &&
            <Button size="sm"  variant="outline-primary" onClick={props.add}>
              <FaUserPlus />
            </Button>
          }
        </div>
      </div>

      <hr className="m-0" />

      <Collapse in={open}>
        <div className=" text-start">
          <div className="mb-1 m-2 w-100 row ">
            {props.children}
          </div>
        </div>
      </Collapse>

    </Card>
  );
};
