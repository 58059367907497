import { CollecionName } from "../config/collection-name";
import { ProductCategoryModel } from "../model/product_category";
import { Repository } from "./repository";

export class FilterProductCategory {

  id = "";
  description = "";

  sort: Partial<ProductCategoryModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductCategoryRepository extends Repository<ProductCategoryModel> {
  constructor() {
    super(CollecionName.PRODUCT_CATEGORY);
  }
}
