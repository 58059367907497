import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import SelectSearch from "../../components/SelectSearch";
import { MessageType } from "../../core/config/message-type";
import { useAuth } from "../../core/contexts/auth";
import { useToast } from "../../core/contexts/toast";
import { ProductDepartmentModel } from "../../core/model/product_department";
import { BranchModel } from "../../core/model/branch";
import { UserModel } from "../../core/model/user";
import { ProductDepartmentRepository } from "../../core/repositories/product_department.repository";
import { UserRepository } from "../../core/repositories/user.repository";
import { BranchRepository } from "../../core/repositories/branch.repository";

interface Props {
  user?: UserModel;
  show: boolean;
  title?: string;
  onHide: () => void;
  onRefresh: () => void;
}

export const ModalUserManager: React.FC<Props> = (props) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(2);
  const [branchList, setBranchList] = useState<BranchModel[]>([]);
  const [departmentList, setDepartmentList] = useState<ProductDepartmentModel[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<any>("");
  const [selectedDepartment, setSelectedDepartment] = useState<any>("");
  const [error, setError] = useState(``);
  const { toastShow } = useToast();
  const { user } = useAuth();

  const _user = new UserRepository();

  useEffect(() => {
    new ProductDepartmentRepository()
      .getFilter({}, "")
      .then((res) => {
        setDepartmentList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });

    new BranchRepository()
      .getFilter({}, "")
      .then((res) => {
        setBranchList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }, []);

  useEffect(() => {
    console.log(selectedDepartment);
  }, [selectedDepartment]);

  useEffect(() => {
    if (props.show) {
      if (props.user) {
        console.log(props.user);
        if (props.user.user_department) setSelectedDepartment(props.user.user_department.split(","));
        else setSelectedDepartment("");

        if (props.user.user_branch) setSelectedBranch(props.user.user_branch.split(","));
        else setSelectedBranch("");

        if (props.user.user_type) setUserType(props.user.user_type);
        else setUserType(2);
      } else {
        setSelectedDepartment("");
        setSelectedBranch("");
        setUserType(2);
      }
      setValidated(false);
      setError("");
      setLoading(false);
    }
  }, [props.show]);

  async function handleSubmit(event: any) {
    setValidated(true);
    const form = event.currentTarget;
    event.preventDefault();
    // event.stopPropagation();
    setLoading(true);

    try {
      const userModel = new UserModel();
      userModel.name = form[0].value;
      // userModel.last_name = form[1].value;
      userModel.email = form[1].value;
      userModel.user_type = userType;
      userModel.user_department = selectedDepartment.toString();
      userModel.user_branch = selectedBranch.toString();

      if (props.user) {
        userModel.id = props.user.id;
      } else {
        if (user) {
          userModel.user_id_creator = user.id;
        }
      }

      await _user.add(userModel);

      props.onRefresh();
      props.onHide();

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.toJSON && error.toJSON().status == 406) {
        setError("O e-mail já existe!");
      } else if (error && typeof error == "string") {
        setError(error);
      } else {
        setError(MessageType.ERROR_TRY);
      }
    }
  }

  return (
    <>
      <Modal centered aria-labelledby="contained-modal-title-vcenter" {...props}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center">
            {props.user ? "Editar" : "Criar Novo"} Usuário
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form id="myform" validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Nome</Form.Label>
              <Form.Control defaultValue={props.user?.name} required type="text" placeholder="Digite o nome..." />
            </Form.Group>

            {/* <Form.Group className="mt-4" controlId="last">
							<Form.Label>Sobrenome</Form.Label>
							<Form.Control defaultValue={props.user?.last_name} required type="text" placeholder="Digite o sobrenome..." />
						</Form.Group> */}
            <Form.Group className="mt-4" controlId="email">
              <Form.Label>E-mail</Form.Label>

              <Form.Control defaultValue={props.user?.email} required disabled={!!props.user} type="email" placeholder="Digite o e-mail..." />
            </Form.Group>

            <Form.Group className="mt-4" controlId="tipo">
              <SelectSearch
                name="Tipo de Usuário"
                selectId="id"
                selectName="description"
                multiple={false}
                required={true}
                value={userType}
                dontShowSearch={true}
                options={[
                  // { id: 1, description: "Administrador" },
                  { id: 2, description: "Interno" },
                  { id: 3, description: "Externo" },
                ]}
                onChange={(value) => {
                  setUserType(value);
                }}
              />
            </Form.Group>

            {userType == 3 && (
              <>
                <Form.Group className="mt-4" controlId="filial">
                  <SelectSearch
                    name="Filial"
                    selectId="id"
                    selectName="description"
                    scroll={true}
                    multiple={true}
                    value={selectedBranch}
                    options={branchList}
                    onChange={(value) => {
                      value = value.toString();
                      setSelectedBranch(value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mt-4" controlId="departamento">
                  <SelectSearch
                    name="Departamento"
                    selectId="id"
                    selectName="description"
                    scroll={true}
                    multiple={true}
                    value={selectedDepartment}
                    options={departmentList}
                    onChange={(value) => {
                      value = value.toString();
                      setSelectedDepartment(value);
                    }}
                  />
                </Form.Group>
              </>
            )}

            {error && (
              <div className="error text-danger mb-2 font-size-12">
                <label> {error}</label>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onHide()}>
            Fechar
          </Button>
          <Button disabled={loading} type="submit" form="myform">
            {loading ? <Spinner size="sm" animation="border" role="status"></Spinner> : props.user ? "Salvar" : "Criar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
