import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { BasePriceCatalogModel, BasePriceCatalogModelGranularidade } from "../../core/model/base_price_catalog";
import Skeleton from "react-loading-skeleton";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { FormatType } from "../../core/config/format-type";
import { formatDecimal } from "../../core/utils/functions";
import { FilterPurchaseOrder, PurchaseOrderRepository } from "../../core/repositories/purchase_order.repository";
import { useToast } from "../../core/contexts/toast";
import { MessageType } from "../../core/config/message-type";
import { PurchaseOrderModel } from "../../core/model/pruchase_order";
import { FilterBasePriceCatalog } from "../../core/repositories/base_price_catalog.repository";
import { AG_GRID_LOCALE_BR } from "../../core/config/AgGridReact";
import moment from "moment";
import "./index.scss";

interface Props {
  show: boolean;
  data?: BasePriceCatalogModel;
  search: FilterBasePriceCatalog;
  subTitle?: string;
  onHide: () => void;
}

export const ModalBuyers: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [title, setTitle] = React.useState<string>("");
  const gridRef = React.useRef<AgGridReact<BasePriceCatalogModel>>(null);

  const [rowData, setRowData] = React.useState<PurchaseOrderModel[]>([]);
  const { toastShow } = useToast();

  React.useEffect(() => {
    setLoading(true);
    console.log(props.data);
    if (props.show && props.data) {
      showLoadingOverlay();
      setRowData(Array.from({ length: 20 }, () => new PurchaseOrderModel()));

      if (props.data.num_level === 5) {
        setTitle(props.data.product_code + " - " + (props.data as any)[BasePriceCatalogModelGranularidade.DESCRICAO[props.data.num_level]]?.toString());
      } else {
        setTitle((props.data as any)[BasePriceCatalogModelGranularidade.DESCRICAO[props.data.num_level]]?.toString());
      }

      const filter = new FilterPurchaseOrder();
      filter.branch_code = props.data.branch_code;
      filter.category_code = props.data.category_code;
      filter.subcategory_code = props.data.subcategory_code;
      filter.department_code = props.data.department_code;
      filter.mesorregion_code = props.data.mesorregion_code;
      filter.product_code = props.data.product_code;
      filter.year_week = props.search.year_week;

      console.log(filter);

      new PurchaseOrderRepository()
        .getFilter(filter, "_post")
        .then((res) => {
          setRowData(res ? res : []);
        })
        .catch(() => {
          setRowData([]);
          toastShow({
            title: "Erro!",
            subTitle: MessageType.ERROR_TRY,
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.show, props.data]);

  const CustomSkeleton = (props: { value: any; money?: boolean; date?: boolean }) => {
    return (
      <>
        {loading ? (
          <Skeleton />
        ) : props.date ? (
          moment(props.value).format(FormatType.MOMENT_FORMAT)
        ) : typeof props.value === "number" ? (
          formatDecimal(props.value, props.money)
        ) : (
          props.value
        )}
      </>
    );
  };
  const columnDefs: ColDef[] = [
    {
      headerName: "Fornecedor",
      field: "supplier_code",
      minWidth: 200,
      flex: 1,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: false,
      cellRenderer: CustomSkeleton,
    },
    {
      headerName: "Produto",
      field: "product_description",
      minWidth: 350,
      flex: 2,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      resizable: false,
      cellRenderer: CustomSkeleton,
    },
    {
      headerName: "Data",
      field: "date_update",
      // valueFormatter: (params: { value: string }) => {
      //   const date = new Date(params.value);
      //   const day = date.getDate().toString().padStart(2, "0");
      //   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero based
      //   const year = date.getFullYear();
      //   return `${day}/${month}/${year}`;
      // },
      minWidth: 180,
      flex: 1,
      floatingFilter: true,
      filter: "agDateColumnFilter",
      resizable: false,
      cellRenderer: (params: any) => <CustomSkeleton value={params.value} date={true} />,
    },
    {
      headerName: "Quantidade",
      field: "total_quantity",
      minWidth: 100,
      flex: 1,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      resizable: false,
      cellRenderer: CustomSkeleton,
    },
    {
      headerName: "Preço",
      field: "unit_price",
      minWidth: 100,
      flex: 1,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      resizable: false,
      cellRenderer: (params: any) => <CustomSkeleton value={params.value} money={true} />,
    },
    {
      headerName: "Total",
      field: "order_value",
      minWidth: 100,
      flex: 1,
      floatingFilter: true,
      filter: "agNumberColumnFilter",
      resizable: false,
      cellRenderer: (params: any) => <CustomSkeleton value={params.value} money={true} />,
    },
  ];

  function showLoadingOverlay() {
    if (props.show && gridRef.current && gridRef.current.api) {
      gridRef.current.componentDidUpdate = () => {
        if (loading && gridRef.current && gridRef.current.api) {
          gridRef.current.api.showLoadingOverlay();
        } else if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.hideOverlay();
        }
      };
    }
  }

  return (
    <>
      <Modal centered aria-labelledby="contained-modal-title-vcenter" size="xl" {...props}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-center">
            Pedidos de compras: {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="ag-theme-quartz w-100" style={{ height: 500 }}>
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              gridId="modalBuyers"
              columnDefs={columnDefs}
              embedFullWidthRows={true}
              pagination={true}
              paginationPageSize={20}
              localeText={AG_GRID_LOCALE_BR}
              overlayLoadingTemplate='<span class="">Carregando...</span>'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              props.onHide();
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
