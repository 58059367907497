import * as React from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../../core/contexts/auth";
import logo from "../../assets/imagens/logo-white.png";
import "./index.scss";
import { check_type } from "../../routes/require_auth";
import { UserType } from "../../core/config/user-type";

const Header: React.FC = () => {
  const { signOut, user } = useAuth();

  const getNavLinkClass = ({ isActive }: { isActive: boolean }) => (isActive ? "nav-link active" : "nav-link");

  return (
    <Navbar collapseOnSelect fixed="top" expand="lg" className="Navbar px-3 py-0" bg="primary">
      <Container>
        <Navbar.Brand href="#">
          <img id="logomain" className="d-inline-block align-top logo" alt="Logo" src={logo} />
        </Navbar.Brand>

        <Nav className="me-auto my-2 my-lg-0">
          <NavLink className={getNavLinkClass} to="/base-price">
            Catálogo de Preço Base
          </NavLink>
          {check_type(UserType.DEV) && (
            <>
              <NavLink className={getNavLinkClass} to="/customer-segmentation">
                Customer Segmentation
              </NavLink>

              <NavLink className={getNavLinkClass} to="/product-matching">
                Product Matching
              </NavLink>

              <NavLink className={getNavLinkClass} to="/matching-validations">
                Validação de Matching
              </NavLink>

              <NavDropdown title="Developer">
                <NavDropdown.Item as={Link} to="/user-management">
                  Usuários
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/chart-cohort-analysis">
                  Chart Cohort Analysis
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/chart-active-cluster">
                  Chart Active Cluster
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/cnpj-hash">
                  CNPJ Hash
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/demand-forecast">
                  Demand Forecast
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/demand-variability">
                  Demand Variability
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/log-api">
                  Log API
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}
        </Nav>

        <Nav className="d-contents nav-gear">
          <NavDropdown title={<BsGearFill className="font-size-14" />}>
            {check_type(UserType.ADMINISTRADOR) && (
              <NavDropdown.Item className="text-primary" as={Link} to="/user-management">
                <FaUser className="mr-1" />
                Usuários
              </NavDropdown.Item>
            )}

            <NavDropdown.Item className="text-primary" onClick={() => signOut(true)}>
              <FaSignOutAlt className="mr-1" />
              Sair
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default Header;
