// @flow
import { Card, Row, Col, Button, Collapse, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import { BsFillEraserFill } from 'react-icons/bs';
import { FaFilter, FaUserPlus } from 'react-icons/fa';
import React, { useState, useEffect, FC } from 'react';
import { FilterUser } from '../../core/repositories/user.repository';
import { ModalUserManager } from './modal';
import { FilterHeader } from '../../components/FilterHeader';

interface Props {
	search: FilterUser;
	onChangeFilter: (search: FilterUser) => void;
}

const Filter: React.FC<Props> = (props) => {
	const [modalShow, setModalShow] = useState(false);

	const [open, setOpen] = useState(false);
	const [fullName, setFullName] = useState('');

	// useEffect(() => {
	// 	props.search.active = active == 'true';
	// 	props.onChangeFilter(props.search);
	// }, [active]);

	function handleSearchName() {
		props.search.full_name = fullName;
		props.onChangeFilter(props.search);
	}
	useEffect(() => {
		const timeOutId = setTimeout(() => handleSearchName(), 1000);
		return () => clearTimeout(timeOutId);
	}, [fullName]);

	return (
		<>
			<div className="stick">
				<FilterHeader title="Cadastro de Usuários"
					add={() => setModalShow(true)}
					clearFilter={() => {
						setFullName('');
						props.onChangeFilter(new FilterUser());
					}}
					reload={() => {
						props.onChangeFilter(props.search);
					}}>
					<Row>
						<Form.Group as={Col} md={10}>
							<Form.Label>Nome Completo</Form.Label>
							<Form.Control
								type="text"
								placeholder="Todos"
								value={fullName}
								onChange={event => setFullName(event.target.value)}
							/>
						</Form.Group>

						<Form.Group as={Col} md={{ span: 2 }}>
							<Form.Label className="font-size-14 form-label">Status</Form.Label>
							<Col md={12}>
								<ToggleButtonGroup value={props.search.active.toString()} type="radio" name="options2" className="w-100"
									onChange={(e) => {
										props.search.active = e == 'true';
										props.onChangeFilter(props.search);
									}}
								>
									<ToggleButton id="tbg-radio-1" variant="outline-primary" value={'true'}>
										Ativo
									</ToggleButton>
									<ToggleButton id="tbg-radio-2" variant="outline-primary" value={'false'}>
										Inativo
									</ToggleButton>
								</ToggleButtonGroup>
							</Col>
						</Form.Group>
					</Row>

				</FilterHeader>

				<ModalUserManager
					show={modalShow}
					onRefresh={() => {
						props.onChangeFilter(props.search);
					}}
					onHide={() => {
						setModalShow(false);
					}}
				/>
			</div>

		</>
	);
};

export default Filter;
