import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { useState, useEffect, FC } from "react";
import { MessageType } from "../../core/config/message-type";
import { FilterProductMatching } from "../../core/repositories/product_matching.repository";
import { ProductDepartmentModel } from "../../core/model/product_department";
import { ProductSectionModel } from "../../core/model/product_section";
import { ProductCategoryModel } from "../../core/model/product_category";
import { ProductModel } from "../../core/model/product";
import { CompetitorModel } from "../../core/model/competitor";
import { ProductDepartmentRepository } from "../../core/repositories/product_department.repository";
import { ProductSectionRepository } from "../../core/repositories/product_section.repository";
import { ProductCategoryRepository } from "../../core/repositories/product_category.repository";
import { ProductRepository } from "../../core/repositories/product.repository";
import { CompetitorRepository } from "../../core/repositories/competitor.repository";
import {
  getNameFilter,
  convertArrayToCsvText,
  matchingRatingList,
  matchingStatusList,
  competitorTypeList,
} from "../../core/utils/functions";
import { FilterHeader } from "../../components/FilterHeader";
import { SearchInput } from "../../components/SearchInput";
import SelectSearch from "../../components/SelectSearch";

interface Props {
  search: FilterProductMatching;
  onChangeFilter: (search: FilterProductMatching) => void;
}

export const Filter: React.FC<Props> = (props) => {
  const { toastShow } = useToast();

  const [productDepartmentList, setProductDepartmentList] = useState<ProductDepartmentModel[]>([]);
  const [productSectionList, setProductSectionList] = useState<ProductSectionModel[]>([]);
  const [productCategoryList, setProductCategoryList] = useState<ProductCategoryModel[]>([]);
  const [productList, setProductList] = useState<ProductModel[]>([]);

  const [productDepartmentShowList, setProductDepartmentShowList] = useState<ProductDepartmentModel[]>([]);
  const [productSectionShowList, setProductSectionShowList] = useState<ProductSectionModel[]>([]);
  const [productCategoryShowList, setProductCategoryShowList] = useState<ProductCategoryModel[]>([]);
  const [productShowList, setProductShowList] = useState<ProductModel[]>([]);

  const [competitorList, setCompetitorList] = useState<CompetitorModel[]>([]);

  useEffect(() => {
    try {
      new ProductDepartmentRepository().getAll().then((res) => {
        setProductDepartmentList(res);
        setProductDepartmentShowList(res);
      });
      new ProductSectionRepository().getAll().then((res) => {
        setProductSectionList(res);
        setProductSectionShowList(res);
      });
      new ProductCategoryRepository().getAll().then((res) => {
        setProductCategoryList(res);
        setProductCategoryShowList(res);
      });
      new ProductRepository().getAll().then((res) => {
        setProductList(res);
        setProductShowList(res);
      });
      new CompetitorRepository().getAll().then((res) => {
        setCompetitorList(res);
      });
    } catch (error) {
      toastShow({
        title: "Erro!",
        subTitle: MessageType.ERROR_TRY,
        type: "error",
      });
    }
  }, []);

  useEffect(() => {
    var _sectionList = productSectionList;
    var _categoryList = productCategoryList;
    var _productList = productList;

    if (props.search.department_id) {
      _sectionList = productSectionList.filter((item) =>
        props.search.department_id.split(",").includes(item.department_id.toString())
      );
      _categoryList = _categoryList.filter((item) => _sectionList.map((i) => i.id).includes(item.section_id));
      _productList = _productList.filter((item) => _categoryList.map((i) => i.id).includes(item.category_id));
    }
    if (props.search.section_id) {
      _categoryList = _categoryList.filter((item) =>
        props.search.section_id.split(",").includes(item.section_id.toString())
      );
      _productList = _productList.filter((item) => _categoryList.map((i) => i.id).includes(item.category_id));
    }
    if (props.search.category_id) {
      _productList = _productList.filter(
        (item) => item.category_id && props.search.category_id.split(",").includes(item.category_id.toString())
      );
    }
    setProductSectionShowList(_sectionList.sort((a, b) => a.description?.localeCompare(b.description)));
    setProductCategoryShowList(_categoryList.sort((a, b) => a.description?.localeCompare(b.description)));
    setProductShowList(_productList.sort((a, b) => a.description?.localeCompare(b.description)));
  }, [props.search]);

  function handleClearFilter() {
    var selects = document.getElementsByTagName("select");
    for (var i = 0; i < selects.length; i++) {
      selects[i].value = "";
    }
    let _filter = new FilterProductMatching();
    props.onChangeFilter(_filter);
  }

  return (
    <div className="stick">
      <FilterHeader
        title="Product Matching"
        clearFilter={handleClearFilter}
        reload={() => {
          props.onChangeFilter(props.search);
        }}
      >
        <Row>
          <Col md={3}>
            <SelectSearch
              name="Concorrente ID"
              selectId="id"
              selectName="description"
              multiple={true}
              value={props.search.competitor_id}
              options={competitorList}
              onChange={(value) => {
                if (Array.isArray(value)) props.search.competitor_id = convertArrayToCsvText(value);
                else props.search.competitor_id = value.toString();
                props.onChangeFilter(props.search);
              }}
            />
          </Col>

          <Col md={2}>
            <SearchInput
              description="Concorrente: Cód. Produto"
              value={props.search.competitor_product_id}
              onChange={(event) => {
                props.search.competitor_product_id = event;
                props.onChangeFilter(props.search);
              }}
            />
          </Col>
          <Col md={3}>
            <SelectSearch
              name="Concorrente: Tipo"
              selectId="id"
              selectName="description"
              multiple={false}
              required={true}
              value={props.search.competitor_issuer_type}
              options={competitorTypeList}
              onChange={(value) => {
                value = value.toString();
                props.search.competitor_issuer_type = value;
                props.search.competitor_issuer_type_description = getNameFilter(
                  competitorTypeList,
                  value,
                  "id",
                  "description"
                );
                props.onChangeFilter(props.search);
              }}
            />
          </Col>
          <Col md={2}>
            <SelectSearch
              name="Status"
              selectId="id"
              selectName="description"
              required={true}
              value={props.search.match_status}
              options={matchingStatusList.filter((item) => item.id !== 1)}
              onChange={(value) => {
                props.search.match_status = value;
                props.search.match_status_description = getNameFilter(matchingStatusList, value, "id", "description");
                props.onChangeFilter(props.search);
              }}
            />
          </Col>
          <Col md={2}>
            <SelectSearch
              name="Avaliação do Modelo"
              selectId="id"
              selectName="description"
              multiple={true}
              value={props.search.match_rating}
              options={matchingRatingList}
              onChange={(value) => {
                value = value.toString();
                props.search.match_rating = value;
                props.search.match_rating_description = getNameFilter(matchingRatingList, value, "id", "description");
                props.onChangeFilter(props.search);
              }}
            />
          </Col>

          <Col md={3}>
            <SelectSearch
              name="Departamento"
              selectId="id"
              selectName="description"
              multiple={true}
              value={props.search.department_id}
              options={productDepartmentShowList}
              onChange={(value) => {
                value = value.toString();
                props.search.department_id = value;
                props.search.department_description = getNameFilter(productDepartmentList, value, "id", "description");
                props.onChangeFilter(props.search);
              }}
            />
          </Col>

          <Col md={2}>
            <SelectSearch
              name="Seção"
              selectId="id"
              selectName="description"
              multiple={true}
              value={props.search.section_id}
              options={productSectionShowList}
              onChange={(value) => {
                value = value.toString();
                props.search.section_id = value;
                props.search.section_description = getNameFilter(productSectionList, value, "id", "description");
                props.onChangeFilter(props.search);
              }}
            />
          </Col>

          <Col md={3}>
            <SelectSearch
              name="Categoria"
              selectId="id"
              selectName="description"
              multiple={true}
              value={props.search.category_id}
              options={productCategoryShowList}
              onChange={(value) => {
                value = value.toString();
                props.search.category_id = value;
                props.search.category_description = getNameFilter(productCategoryList, value, "id", "description");
                props.onChangeFilter(props.search);
              }}
            />
          </Col>

          <Col md={2}>
            <SelectSearch
              name="Produto"
              selectId="id"
              selectName="description_show"
              multiple={true}
              value={props.search.product_id}
              options={productShowList}
              onChange={(value) => {
                value = value.toString();
                props.search.product_id = value;
                props.search.product_description = getNameFilter(productList, value, "id", "description");
                props.onChangeFilter(props.search);
              }}
            />
          </Col>

          <Col md={2}>
            <SelectSearch
              name="Tipo de Matching"
              selectId="id"
              selectName="description"
              multiple={false}
              required={true}
              value={props.search.is_similar}
              options={[
                { id: "", description: "Todos" },
                { id: true, description: "Similar" },
                { id: false, description: "Específico" },
              ]}
              onChange={(value) => {
                props.search.is_similar = value == "" ? null : value;
                props.onChangeFilter(props.search);
              }}
            />
          </Col>
        </Row>
      </FilterHeader>
    </div>
  );
};
